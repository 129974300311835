<template>
  <div class="right-content-section">
    <div class="rate-header">
      <div class="rate">
        <div class="tableContainer">
          <div>
            <v-simple-table class="first-table" dense item-key="name">
              <template v-slot:top>
                <thead
                  class="table-first-col"
                  :style="{
                    backgroundColor: truck.enable ? '#E33029' : '#404040',
                  }"
                >
                  <tr>
                    <th
                      class="pl-2 text-left rate-title rounded-l-lg"
                      :style="mobileTitleStyles"
                    >
                      Fee
                    </th>
                  </tr>
                </thead>

                <tbody class="options">
                  <tr
                    v-for="(fee, feeIndex) in feesList"
                    :key="feeIndex"
                    style="height: 39px"
                  >
                    <td class="mileage-title pl-3">
                      {{ fee }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>

          <div class="second-table">
            <v-simple-table dense item-key="name" class="d-flex">
              <template v-slot:top>
                <template v-for="(feeItem, feeItemIndex) of extraFeesList">
                  <div :key="feeItemIndex">
                    <thead
                      class="table-content-col"
                      :style="{
                        backgroundColor: truck.enable ? '#E33029' : '#404040',
                      }"
                    >
                      <tr>
                        <th
                          class="text-left"
                          :class="{
                            'rounded-r-lg':
                              feeItemIndex === extraFeesList.length - 1,
                          }"
                        >
                          <v-btn
                            class="ma-2 rate-btn"
                            :class="{
                              'selected-ratebtn': feeItem && truck.enable,
                            }"
                            outlined
                            :disabled="!truck.enable"
                          >
                            <span>{{ feeItem.name }}</span>
                          </v-btn>
                        </th>
                      </tr>
                    </thead>

                    <tbody class="options">
                      <tr
                        v-for="(fee, feeIndex) in feeItem.fees"
                        :key="feeIndex"
                        style="height: 39px"
                      >
                        <td>
                          <div
                            class="ma-2 option-input d-flex align-center"
                            :class="{
                              'enable-option': !fee.readonly && truck.enable,
                            }"
                          >
                            <div class="d-flex" style="width: inherit">
                              <span class="currency">$</span>

                              <input
                                @blur="
                                  updateTruckFee(fee, feeIndex, feeItemIndex)
                                "
                                type="text"
                                class="ml-0 mileage-btn"
                                v-model="fee.cost"
                                :class="{
                                  'active-mileage-btn': true,
                                  'unactive-mileage-btn': truck.enable,
                                }"
                                :disabled="fee.readonly || !truck.enable"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </div>
                </template>
              </template>
            </v-simple-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.configure-region-rates {
  @import "@/assets/styles/ConfigureRegionRates.scss";

  font-family: "Outfit", sans-serif;
  background-color: #f4f4f4;
  padding: 25px;
}
</style>

<script>
export default {
  name: "FeeTable",
  props: {
    feesList: { type: Array, required: true },
    extraFeesList: { type: Array, required: true },
    truck: { type: Object, required: true },
    windowWidth: { type: Number, required: true },
  },
  inject: ["updateTruckFee"],
  computed: {
    mobileTitleStyles() {
      return {
        padding: this.windowWidth <= 600 ? "13px 0" : "",
      };
    },
  },
};
</script>
