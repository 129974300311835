<template>
  <label class="switch">
    <input type="checkbox" :checked="checked" @change="toggleChecked" />

    <span class="slider" />
  </label>
</template>

<style lang="scss" scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    border-radius: 34px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #e33029;
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }
}
</style>

<script>
export default {
  name: "FormSwitch",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    checked() {
      return this.value;
    },
  },
  methods: {
    toggleChecked(event) {
      this.$emit("input", event.target.checked);
    },
  },
};
</script>
