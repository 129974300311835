<template>
  <div>
    <MobileHeader
      @route-clicked="saveRoute"
      :is-form-dirty="isDirty"
      :is-page-loaded="isLoading"
    />

    <v-row style="background-color: #f1f1f1">
      <v-col style="background-color: #f1f1f1">
        <div class="configure-region-rates">
          <div class="header-txt-content align-center mb-7">
            <img
              class="back-icon"
              @click="saveRoute('/region-list')"
              src="@/assets/icons/black-back-arrow.png"
            />

            <span
              class="back-btn-txt black--text"
              @click="saveRoute('/region-list')"
            >
              Back to region and rates
            </span>
          </div>

          <div class="main-content">
            <div class="header-section d-flex">
              <div class="header-section-left">
                <p class="region-title mb-2" v-if="!isLoading">
                  {{ localStorage.username }}
                </p>

                <p class="region-title mb-2" v-if="isLoading">
                  <v-skeleton-loader
                    class="skeleton-mini-width skeleton-remove-padding"
                    max-width="120"
                    type="list-item"
                  />
                </p>

                <div class="location-map-icon d-flex align-start">
                  <v-img src="@/assets/icons/location-map-marker.png" />

                  <p v-if="!isLoading">{{ regionName }}</p>

                  <div class="skeleton-full-width" v-if="isLoading">
                    <v-skeleton-loader
                      class="skeleton-mini-width skeleton-remove-padding"
                      max-width="120"
                      type="list-item"
                    />
                  </div>
                </div>

                <p v-if="!isLoading" class="title-info">
                  Configure your rates for {{ regionName }} region
                </p>
              </div>

              <div class="header-section-mid mt-3 mt-md-0">
                <div class="info-section">
                  <img src="@/assets/icons/info-icon.png" />

                  <p class="header-section-title mb-2">
                    How to configure rates
                  </p>
                </div>

                <p v-if="!isLoading" class="header-section-info">
                  Configure your rates for {{ regionName }} region
                </p>
              </div>

              <div class="header-section-right">
                <v-dialog v-model="dialog" persistent max-width="430">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="save-changes-btn white--text mb-0"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      block
                      color="black"
                    >
                      Save Changes
                    </v-btn>
                  </template>

                  <v-card class="pa-6 outfit-font-family">
                    <v-card-title
                      class="pl-0 pb-1 pt-0 pr-0 letter-spacing-08 font-weight-bold d-flex justify-space-between"
                    >
                      Save Changes?

                      <v-icon class="black--text" @click="dialog = false">
                        mdi-close
                      </v-icon>
                    </v-card-title>

                    <div class="d-flex align-center align-start mb-2 pl-0">
                      <img width="15px" src="@/assets/icons/info-icon.png" />

                      <span
                        size="12"
                        class="pl-1 font-weight-medium info-txt-size letter-spacing-08"
                      >
                        Click save button to save your changes.
                      </span>
                    </div>

                    <v-card-actions class="pa-0">
                      <v-spacer />

                      <v-btn
                        class="modal-btns text-capitalize rounded-md font-weight-bold letter-spacing-08"
                        width="48%"
                        dense
                        outlined
                        text
                        @click="dialog = false"
                      >
                        Discard Changes
                      </v-btn>

                      <v-btn
                        class="white--text text-capitalize rounded-md font-weight-medium letter-spacing-08"
                        dense
                        width="48%"
                        dark
                        color="black"
                        @click="
                          dialog = false;
                          saveRegion();
                        "
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </div>

            <div class="content-section" v-if="!isLoading">
              <template v-for="(truck, truckIndex) in truckOptions">
                <div
                  class="content d-flex flex-column flex-md-row"
                  :key="truck.offering_service_region_id"
                >
                  <div
                    class="left-content-section d-flex flex-row flex-sm-column justify-space-between justify-sm-start"
                  >
                    <div class="d-flex flex-column">
                      <p>Truck Type</p>

                      <div class="location-map-icon d-flex align-center mb-1">
                        <v-icon class="text-center icon-color">
                          mdi-truck
                        </v-icon>

                        <p
                          class="region-name"
                          v-if="truck.service_name && !isLoading"
                        >
                          {{ truck.service_name }}
                        </p>

                        <p class="region-name" v-if="isLoading">
                          <v-skeleton-loader
                            class="skeleton-mini-width"
                            max-width="100"
                            type="list-item"
                          />
                        </p>
                      </div>
                    </div>

                    <div class="region-detail d-flex flex-column">
                      <div
                        class="region-option toggle-service d-flex flex-column"
                      >
                        <span>
                          {{ !!truck.enable ? "Disable?" : "Enable?" }}
                        </span>

                        <v-switch
                          :label="`Yes`"
                          inset
                          dense
                          v-model="truck.enable"
                          hide-details="true"
                          class="mt-2"
                          @change="
                            enableDisableRegion(
                              truck.region_id,
                              truck.offering_service_id,
                              truck.enable
                            )
                          "
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <RateTable
                      :custom-placeholdes-per-row="['$300', '$5']"
                      display-title="Mileage Rate"
                      :rows-list="mileageList"
                      :truck="truck"
                      :truck-index="truckIndex"
                      truck-rate-enabled-column="is_mileage_enabled"
                      truck-rate-pricing-column="truck_mileage_pricing"
                      :window-width="windowWidth"
                    />

                    <br />

                    <RateTable
                      :custom-placeholdes-per-row="['$300', '$120/hr']"
                      display-title="Hourly Rate"
                      :rows-list="hourlyList"
                      :truck="truck"
                      :truck-index="truckIndex"
                      truck-rate-enabled-column="is_hourly_enabled"
                      truck-rate-pricing-column="truck_hourly_pricing"
                      :window-width="windowWidth"
                    />

                    <br />

                    <FeeTable
                      :fees-list="feesList"
                      :extra-fees-list="extraFees"
                      :truck="truck"
                      :window-width="windowWidth"
                    />

                    <div
                      class="accessories-warning"
                      v-html="accessoriesRequirementsWarning"
                    />
                  </div>
                </div>
              </template>
            </div>

            <ViewSkeleton :is-loading="isLoading" />
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="changesConfirmSpec" persistent max-width="440">
      <v-card class="pa-6 accept-order-vcard">
        <v-card-title
          class="reject-order-main-title pl-0 pb-1 pt-0 pr-0 d-flex justify-space-between"
        >
          Are you sure?
        </v-card-title>

        <div class="title-info d-flex align-center align-start mb-2 pl-0">
          <span class="pl-1 font-weight-bold accept-order-info">
            Changes made will be lost
          </span>
        </div>

        <v-card-actions class="pa-0">
          <v-spacer />

          <v-btn
            class="accept-order-btns rounded-md"
            width="50%"
            dense
            outlined
            text
            @click="changesConfirmSpec = false"
          >
            No
          </v-btn>

          <v-btn
            class="accept-order-btns white--text rounded-md"
            dense
            width="50%"
            dark
            color="black"
            @click="
              changesConfirmSpec = false;
              loadRoute();
            "
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
.configure-region-rates {
  @import "@/assets/styles/ConfigureRegionRates.scss";

  font-family: "Outfit", sans-serif;
  background-color: #f4f4f4;
  padding: 25px;

  .toggle-service {
    height: 100% !important;
    padding: 10px 16px !important;
  }
}
</style>

<style lang="scss">
.accessories-warning {
  width: 100%;
  margin-top: 30px;

  ul li {
    font-size: 15px;
  }
}
</style>

<script>
import MobileHeader from "@/components/mobile-header.vue";
import mixin from "@/mixins/auth.mixin.js";
import FeeTable from "@/views/ConfigureRegionRates/components/FeeTable.vue";
import RateTable from "@/views/ConfigureRegionRates/components/RateTable.vue";
import ViewSkeleton from "@/views/ConfigureRegionRates/components/ViewSkeleton.vue";

export default {
  name: "ConfigureRegionRates",
  components: {
    FeeTable,
    MobileHeader,
    RateTable,
    ViewSkeleton,
  },
  data: () => ({
    authMixins: mixin.methods,
    dialog: false,
    changesConfirmSpec: false,
    mileageList: [
      "Min cost",
      "0-50 / miles",
      "51-100 / miles",
      "101-150 / miles",
      "151-200 / miles",
      "201-250 / miles",
      "251-300 / miles",
      "301+ miles",
    ],
    hourlyList: ["Min cost", "Per hour"],
    feesList: ["Stop", "Layover", "Tarps", "Detention", "First 2 hours free"],
    truckOptions: [],
    extraFees: [],
    selectedLoadType: -1,
    windowWidth: 0,
    selectedTruckIndex: -1,
    selectedRateIndex: -1,
    selectedTruckRate: null,
    regionId: null,
    regionName: "",
    isDirty: false,
    isLoading: false,
    forwardRoute: "",
    accessoriesRequirementsWarning: "",
  }),
  computed: {
    localStorage() {
      return localStorage;
    },
  },
  provide() {
    return {
      saveChanges: this.saveChanges,
      saveRegion: this.saveRegion,
      selectItem: this.selectItem,
      toggleColumn: this.toggleColumn,
      updateFormStatus: this.updateFormStatus,
      updateTruckFee: this.updateTruckFee,
    };
  },
  created() {
    this.updateWindowWidth();
    const warningInterval = setInterval(() => {
      if (localStorage.portalConfigs) {
        this.accessoriesRequirementsWarning = JSON.parse(
          localStorage.portalConfigs
        ).accessoriesRequirementsWarning;
      }
      if (this.accessoriesRequirementsWarning) {
        clearInterval(warningInterval);
      }
    }, 1000);
  },
  filters: {
    mileageFormatting(mileage) {
      if (!mileage) return "Minimum cost";
      let actualMileageFormat = mileage.split(",");
      let reformattedMileage = "";

      if (actualMileageFormat[0] !== "301")
        reformattedMileage =
          actualMileageFormat[0] + "-" + actualMileageFormat[1] + " / miles";
      else reformattedMileage = "301+ miles";

      return reformattedMileage;
    },
  },
  mounted() {
    document.title = "Carrier Portal - Breaker19.app";

    window.addEventListener("resize", this.updateWindowWidth);

    this.regionId = this.$route.params.regionId;
    this.regionName = this.$route.params.regionName;

    window.addEventListener("beforeunload", this.handleBeforeUnload);

    this.loadRegionConfig();
  },
  methods: {
    sortFees(fees) {
      const order = ["stop", "layover", "tarps", "detention"];
      return fees.sort((a, b) => {
        return order.indexOf(a.type) - order.indexOf(b.type);
      });
    },

    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },

    saveRoute(routeUrl) {
      if (this.isDirty) {
        this.changesConfirmSpec = true;
        this.forwardRoute = routeUrl;
      } else {
        this.$router.push(routeUrl);
      }
    },

    loadRoute() {
      this.$router.push(this.forwardRoute);
    },

    updateTruckFee(fee, priceIndex, rateIndex) {
      const inputValue = fee.cost.trim();
      const sanitizedValue = inputValue.replace(/[^\d.]/g, "");
      let number = parseFloat(sanitizedValue);
      let formattedNumber = number.toFixed(2);
      this.extraFees[rateIndex].fees[priceIndex].cost = formattedNumber;

      if (formattedNumber < 0 || fee.cost == "") {
        this.extraFees[rateIndex].fees[priceIndex].cost = 0.01;
        this.$toast.error(
          "Oops, our min per fee rate we allow at this time is $0.01, please try again.",
          {}
        );
      }
    },

    updateFormStatus(
      truckRatePricingColumn,
      price,
      truckIndex,
      priceIndex,
      rateIndex
    ) {
      this.isDirty = true;

      const inputValue = price.price.trim();
      const sanitizedValue = inputValue.replace(/[^\d.]/g, "");

      let number = parseFloat(sanitizedValue || 0);
      let formattedNumber = number.toFixed(2);

      this.truckOptions[truckIndex].service_offering_trucks[rateIndex][
        truckRatePricingColumn
      ][priceIndex].price = formattedNumber;

      if (truckRatePricingColumn === "truck_hourly_pricing") {
        if (formattedNumber <= 0) {
          this.$toast.error(
            "Oops, our min per hour rate we allow at this time is $0.01 per hour, please try again.",
            { duration: 5000 }
          );

          this.truckOptions[truckIndex].service_offering_trucks[rateIndex][
            truckRatePricingColumn
          ][priceIndex].price = "0.01";
        }

        return;
      }

      if (formattedNumber > 15 && priceIndex !== 0) {
        this.truckOptions[truckIndex].service_offering_trucks[rateIndex][
          truckRatePricingColumn
        ][priceIndex].price = "15.00";

        this.$toast.error(
          "Oops, our max per mile rate we allow at this time is $15 per mile, please try again.",
          { duration: 5000 }
        );
      } else if (formattedNumber < 0.01 || price.price == "") {
        this.truckOptions[truckIndex].service_offering_trucks[rateIndex][
          truckRatePricingColumn
        ][priceIndex].price = "0.01";

        this.$toast.error(
          "Oops, our min per mile rate we allow at this time is $0.01 per mile, please try again.",
          { duration: 5000 }
        );
      }
    },

    handleBeforeUnload(event) {
      if (this.isDirty) {
        const warningMessage =
          "You have unsaved changes! Are you sure you want to leave?";
        event.returnValue = warningMessage;
        return warningMessage;
      }
    },

    toggleColumn(
      truckRateEnabledColumn,
      truckRatePricingColumn,
      truckId,
      index
    ) {
      for (let truckOption of this.truckOptions) {
        if (truckOption.offering_service_region_id === truckId) {
          const truck = truckOption.service_offering_trucks[index];

          truck[truckRateEnabledColumn] = !truck[truckRateEnabledColumn];

          for (let price of truck[truckRatePricingColumn]) {
            price.enabled = !!truck[truckRateEnabledColumn];
          }
        }
      }

      this.isDirty = true;
    },

    selectItem(
      truckRateEnabledColumn,
      truckRatePricingColumn,
      truckId,
      truckIndex,
      rateIndex,
      priceIndex,
      status
    ) {
      if (!this.truckOptions?.[truckIndex]?.enable) return;

      for (let truckOption of this.truckOptions) {
        if (truckOption.offering_service_region_id === truckId) {
          const truck = truckOption.service_offering_trucks[rateIndex];

          truck[truckRatePricingColumn][priceIndex].enabled = status;

          if (status) {
            truck[truckRateEnabledColumn] = true;
          } else {
            const allUnselected = truck[truckRatePricingColumn].every(
              (price) => price.enabled === false
            );

            if (allUnselected) {
              truck[truckRateEnabledColumn] = false;
            }
          }
        }
      }

      this.isDirty = true;
    },

    saveChanges() {
      this.truckOptions[this.selectedTruckIndex].service_offering_trucks[
        this.selectedRateIndex
      ] = this.selectedTruckRate;
      this.selectedLoadType = -1;
    },

    async loadRegionConfig() {
      this.isLoading = true;

      let config = { headers: { Authorization: localStorage.authToken } };

      try {
        const rateResponse = await this.$api.get(
          `carrier/region/${this.regionId}/services`,
          config
        );

        if (rateResponse.status === 200) {
          const response = await this.$api.get(
            `carrier/hotshot/extra-fees`,
            config
          );

          response.data.data.forEach((item) => {
            item.fees = this.sortFees(item.fees);
          });

          this.extraFees = response.data.data;
          this.truckOptions = rateResponse.data.data;
        }
      } catch (err) {
        this.$toast.error("Failed to get region rate data", {});
      } finally {
        this.isLoading = false;
        this.requestStatus = false;
      }
    },

    async saveRegion() {
      const config = { headers: { Authorization: localStorage.authToken } };

      let regionData = this.truckOptions;
      let payloads = [];

      this.extraFees.forEach(({ id, fees }) => {
        payloads.push({
          truck_id: id,
          fees: [{ type: "stop", cost: fees[0].cost }],
        });
      });

      try {
        const regionResponse = await this.$api.post(
          `carrier/region/add-services`,
          regionData,
          config
        );

        if (regionResponse.status === 200) {
          this.truckOptions = regionResponse.data.data;

          const extraFeesResponse = await this.$api.patch(
            `carrier/hotshot/extra-fees`,
            { trucks_fees: payloads },
            config
          );

          if (extraFeesResponse.status === 200) {
            extraFeesResponse.data.data.forEach((item) => {
              item.fees = this.sortFees(item.fees);
            });

            this.extraFees = extraFeesResponse.data.data;
            this.$toast.success("Region service updated successfully", {});
            this.isDirty = false;
          }
        }
      } catch (err) {
        this.$toast.error("Region service update failed", {});
      } finally {
        this.requestStatus = false;
      }
    },

    async enableDisableRegion(regionId, serviceId, enableStatus) {
      if (this.requestStatus) return;

      this.requestStatus = true;

      const config = { headers: { Authorization: localStorage.authToken } };

      try {
        this.truckOptions.forEach((truckOption) => {
          truckOption?.service_offering_trucks?.forEach((truck) => {
            truck.is_mileage_enabled = enableStatus;
            truck.is_hourly_enabled = enableStatus;

            truck?.truck_mileage_pricing?.forEach((price) => {
              price.enabled = enableStatus;
            });

            truck?.truck_hourly_pricing?.forEach((price) => {
              price.enabled = enableStatus;
            });
          });
        });

        const regionData = this.truckOptions;
        const regionResponse = await this.$api.post(
          `carrier/region/add-services`,
          regionData,
          config
        );

        if (regionResponse.status === 200) {
          await this.$api.patch(
            `carrier/region/enable/${regionId}/${serviceId}`,
            { enabled: enableStatus },
            config
          );

          this.requestStatus = false;

          if (enableStatus) {
            this.$toast.success("Service enabled", {});
            this.$toast.info("Please Set Rates For Trucks", {});
          } else {
            this.$toast.success("Service disabled", {});
          }
        }
      } catch (error) {
        this.requestStatus = false;

        if (enableStatus) {
          this.$toast.error("Service enable failed", {});
        } else {
          this.$toast.error("Service disable failed", {});
        }
      }
    },
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowWidth);
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
};
</script>
