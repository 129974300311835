var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right-content-section"},[_c('div',{staticClass:"rate-header"},[_c('div',{staticClass:"rate"},[_c('div',{staticClass:"tableContainer"},[_c('div',[_c('v-simple-table',{staticClass:"first-table",attrs:{"dense":"","item-key":"name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('thead',{staticClass:"table-first-col",style:({
                  backgroundColor: _vm.truck.enable ? '#E33029' : '#404040',
                })},[_c('tr',[_c('th',{staticClass:"pl-2 text-left rate-title rounded-l-lg",style:(_vm.mobileTitleStyles)},[_vm._v(" Fee ")])])]),_c('tbody',{staticClass:"options"},_vm._l((_vm.feesList),function(fee,feeIndex){return _c('tr',{key:feeIndex,staticStyle:{"height":"39px"}},[_c('td',{staticClass:"mileage-title pl-3"},[_vm._v(" "+_vm._s(fee)+" ")])])}),0)]},proxy:true}])})],1),_c('div',{staticClass:"second-table"},[_c('v-simple-table',{staticClass:"d-flex",attrs:{"dense":"","item-key":"name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_vm._l((_vm.extraFeesList),function(feeItem,feeItemIndex){return [_c('div',{key:feeItemIndex},[_c('thead',{staticClass:"table-content-col",style:({
                      backgroundColor: _vm.truck.enable ? '#E33029' : '#404040',
                    })},[_c('tr',[_c('th',{staticClass:"text-left",class:{
                          'rounded-r-lg':
                            feeItemIndex === _vm.extraFeesList.length - 1,
                        }},[_c('v-btn',{staticClass:"ma-2 rate-btn",class:{
                            'selected-ratebtn': feeItem && _vm.truck.enable,
                          },attrs:{"outlined":"","disabled":!_vm.truck.enable}},[_c('span',[_vm._v(_vm._s(feeItem.name))])])],1)])]),_c('tbody',{staticClass:"options"},_vm._l((feeItem.fees),function(fee,feeIndex){return _c('tr',{key:feeIndex,staticStyle:{"height":"39px"}},[_c('td',[_c('div',{staticClass:"ma-2 option-input d-flex align-center",class:{
                            'enable-option': !fee.readonly && _vm.truck.enable,
                          }},[_c('div',{staticClass:"d-flex",staticStyle:{"width":"inherit"}},[_c('span',{staticClass:"currency"},[_vm._v("$")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(fee.cost),expression:"fee.cost"}],staticClass:"ml-0 mileage-btn",class:{
                                'active-mileage-btn': true,
                                'unactive-mileage-btn': _vm.truck.enable,
                              },attrs:{"type":"text","disabled":fee.readonly || !_vm.truck.enable},domProps:{"value":(fee.cost)},on:{"blur":function($event){return _vm.updateTruckFee(fee, feeIndex, feeItemIndex)},"input":function($event){if($event.target.composing)return;_vm.$set(fee, "cost", $event.target.value)}}})])])])])}),0)])]})]},proxy:true}])})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }