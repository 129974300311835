<template>
  <div>
    <div class="profile-pic-heading">
      <p class="mb-0">Notification Settings</p>
    </div>

    <div class="profile-edit-section d-flex flex-column align-center mt-10">
      <div class="notification-toggle-row">
        <label for="push-notifications">Push Notifications</label>

        <FormSwitch id="push-notifications" v-model="formData.isPush" />
      </div>

      <div class="notification-toggle-row">
        <label for="sms-notifications">SMS Notifications</label>

        <FormSwitch id="sms-notifications" v-model="formData.isSms" />
      </div>

      <div class="notification-toggle-row">
        <label for="email-notifications">Email Notifications</label>

        <FormSwitch id="email-notifications" v-model="formData.isEmail" />
      </div>

      <div class="notification-toggle-row">
        <label for="chat-notifications">Chat Notifications</label>

        <FormSwitch id="chat-notifications" v-model="formData.isChat" />
      </div>

      <div class="order-toggle-options-wrapper">
        <div class="notification-toggle-row">
          <div class="toggle-title">
            <label for="email-notifications">Order Updates</label>

            <span>Enable notifications about truck status</span>
          </div>

          <FormSwitch
            id="email-notifications"
            v-model="formData.isTruckStatus"
          />
        </div>

        <hr />

        <div class="order-toggle-options">
          <div class="order-toggle-options-item">
            <label for="order-update-notifications">Order Update</label>

            <FormSwitch
              id="order-update-notifications"
              v-model="formData.isOrderUpdates"
            />
          </div>

          <div class="order-toggle-options-item">
            <label for="order-modified-notifications">Order Modified</label>

            <FormSwitch
              id="order-modified-notifications"
              v-model="formData.isOrderModified"
            />
          </div>

          <div class="order-toggle-options-item">
            <label for="order-in-route-notifications">Order In Route</label>

            <FormSwitch
              id="order-in-route-notifications"
              v-model="formData.isOrderInRoute"
            />
          </div>

          <div class="order-toggle-options-item">
            <label for="order-complete-notifications">Order Complete</label>

            <FormSwitch
              id="order-complete-notifications"
              v-model="formData.isOrderComplete"
            />
          </div>

          <div class="order-toggle-options-item">
            <label for="invoice-notifications">Invoice Notification</label>

            <FormSwitch
              id="invoice-notifications"
              v-model="formData.isInvoiceCreated"
            />
          </div>
        </div>
      </div>

      <div class="notification-toggle-row">
        <label for="announcements-notifications">Breaker19 Announcements</label>

        <FormSwitch
          id="announcements-notifications"
          v-model="formData.isAnnouncements"
        />
      </div>

      <button
        type="button"
        @click="handleSubmit"
        :disabled="fetchLoading || updateLoading"
      >
        Save
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/styles/ManageProfile.scss";

$border-color: #e0e0e0;

.order-toggle-options-wrapper {
  width: 100%;
  margin-bottom: 4px;

  .notification-toggle-row {
    border-bottom: none;
    border-end-start-radius: 0;
    border-end-end-radius: 0;
    margin-bottom: 0;
  }

  .order-toggle-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $border-color;
    border-end-start-radius: 8px;
    border-end-end-radius: 8px;

    .order-toggle-options-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 20px;
      font-size: 0.875rem;

      label:first-of-type {
        margin-left: 40px;
      }
    }
  }
}

.notification-toggle-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 4px 20px;

  font-weight: 600;
  font-size: 0.875rem;
  border-radius: 8px;
  border: 1px solid $border-color;

  margin: 4px 0;

  &:has(.toggle-title) {
    padding: 10px 20px;
  }

  .toggle-title {
    display: flex;
    flex-direction: column;

    span {
      font-weight: 400;
      font-size: 0.75rem;
    }
  }
}

button {
  color: white;
  background: black;
  font-size: 1.25rem;
  font-weight: 600;
  height: 48px;
  padding: 4px 38px;
  margin: 12px 0 0 auto;
  border-radius: 8px;
}
</style>

<script>
import FormSwitch from "@/components/form-switch.vue";
import mixin from "@/mixins/auth.mixin.js";

export default {
  name: "NotificationSettings",
  components: { FormSwitch },
  data: () => ({
    authMixins: mixin.methods,
    fetchLoading: false,
    updateLoading: false,
    formData: {
      isPush: false,
      isSms: false,
      isEmail: false,
      isOrderUpdates: false,
      isOrderModified: false,
      isOrderInRoute: false,
      isOrderComplete: false,
      isTruckStatus: false,
      isInvoiceCreated: false,
      isChat: false,
      isAnnouncements: false,
    },
  }),
  mounted() {
    this.fetchNotificationsSettings();
  },
  methods: {
    async fetchNotificationsSettings() {
      const config = { headers: { Authorization: localStorage.authToken } };

      this.fetchLoading = true;

      try {
        const response = await this.$api.get("notification-settings", config);
        const data = response.data?.data;

        this.formData.isPush = data?.is_push || false;
        this.formData.isSms = data?.is_sms || false;
        this.formData.isEmail = data?.is_email || false;
        this.formData.isTruckStatus = data?.is_truck_status || false;
        this.formData.isOrderUpdates = data?.is_order_updates || false;
        // this.formData.isOrderModified = data?.is_order_modified || false;
        this.formData.isOrderInRoute = data?.is_order_in_route || false;
        this.formData.isOrderComplete = data?.is_order_complete || false;
        this.formData.isInvoiceCreated = data?.is_invoice_created || false;
        // this.formData.isChat = data?.is_chat || false;
        this.formData.isAnnouncements = data?.is_announcements || false;
      } catch (error) {
        this.$toast.error("Failed to retrieve notifications");
      } finally {
        this.fetchLoading = false;
      }
    },
    async handleSubmit() {
      const config = { headers: { Authorization: localStorage.authToken } };

      this.updateLoading = true;

      try {
        const payload = {
          is_push: this.formData.isPush,
          is_sms: this.formData.isSms,
          is_email: this.formData.isEmail,
          is_order_updates: this.formData.isOrderUpdates,
          // is_order_modified: this.formData.isOrderModified,
          is_order_in_route: this.formData.isOrderInRoute,
          is_order_complete: this.formData.isOrderComplete,
          is_truck_status: this.formData.isTruckStatus,
          is_announcements: this.formData.isAnnouncements,
          // is_chat: this.formData.isChat,
          is_invoice_created: this.formData.isInvoiceCreated,
        };

        await this.$api.put("notification-settings", payload, config);

        this.$toast.success("Notifications updated");
      } catch (error) {
        this.$toast.error("Failed to update notifications");
      } finally {
        this.updateLoading = false;
      }
    },
  },
  watch: {
    "formData.isTruckStatus": function (newValue) {
      if (typeof newValue === "boolean" && !newValue) {
        this.formData.isOrderUpdates = false;
        this.formData.isOrderModified = false;
        this.formData.isOrderInRoute = false;
        this.formData.isOrderComplete = false;
        this.formData.isInvoiceCreated = false;
      }
    },
  },
};
</script>
