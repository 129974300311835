<template>
  <div class="homepage-styles">
    <MobileHeader :isPageLoaded="isLoading"></MobileHeader>

    <v-row>
      <v-col>
        <p class="main-title pt-7">
          Welcome <br class="break-line-status" />
          <span>{{ localStorage.username }}</span>
        </p>
        <div id="pending-shipments" class="main-content pa-5">
          <div class="d-flex align-center justify-space-between">
            <p class="section-title mb-0 mb-sm-2">Pending Shipments</p>

            <v-btn
              @click="navigateTo('/shipment-list', 10)"
              class="d-none d-sm-block white--text rounded-md"
              dark
              dense
              width="180"
              color="black"
            >
              View all {{ totals[1] !== undefined ? `(${totals[1]})` : "" }}
            </v-btn>
          </div>

          <div class="middle-content">
            <div
              style="background-color: #fff"
              :class="{
                'tablet-mobile-grid-view':
                  pendingShipmentList &&
                  pendingShipmentList.length !== 0 &&
                  windowWidth < 1264,
              }"
            >
              <template v-for="(shipment, s) of pendingShipmentList">
                <v-row
                  :key="shipment.turvo_id"
                  :class="{ 'pr-4': windowWidth < 1264 }"
                  class="section-card mt-6 no-gutters"
                >
                  <div
                    class="single-card d-flex align-center flex-column flex-lg-row info-mobile-view"
                  >
                    <div
                      class="item-list-info-pending info-mobile-view d-flex flex-column align-self-center"
                    >
                      <p class="mini-titles">Status</p>
                      <div class="shipment-status-pending">
                        Awaiting Response
                      </div>
                    </div>

                    <div
                      class="item-list-info-pending d-flex flex-row-reverse flex-lg-row info-mobile-view justify-end"
                    >
                      <div class="d-flex flex-column ml-5 ml-lg-0">
                        <p class="mini-titles">Created Date</p>
                        <div class="mini-titles-val" v-if="shipment.created_at">
                          {{
                            formatDate(shipment.created_at).format("MM/DD/YYYY")
                          }}
                        </div>
                        <div
                          class="mini-titles-val"
                          v-if="!shipment.created_at"
                        >
                          -
                        </div>
                      </div>
                      <div class="d-flex flex-column ml-5 ml-lg-0">
                        <p class="mini-titles">Pickup Date</p>
                        <div
                          class="mini-titles-val"
                          v-if="shipment.pickup_time"
                        >
                          {{
                            formatDate(shipment.pickup_time).format(
                              "MM/DD/YYYY"
                            )
                          }}
                        </div>
                        <div
                          class="mini-titles-val"
                          v-if="!shipment.pickup_time"
                        >
                          As soon as possible
                        </div>
                      </div>

                      <div class="d-flex flex-column">
                        <p class="mini-titles">Pickup Time</p>
                        <div
                          class="mini-titles-val"
                          v-if="shipment.pickup_time"
                        >
                          {{
                            formatDate(shipment.pickup_time).format(
                              "h:mm a (z)"
                            )
                          }}
                        </div>
                        <div
                          class="mini-titles-val"
                          v-if="!shipment.pickup_time"
                        >
                          -
                        </div>
                      </div>
                    </div>

                    <div
                      class="item-list-info-pending d-flex justify-lg-center info-mobile-view ml-lg-5"
                    >
                      <div class="d-flex flex-column sub-section">
                        <p class="mini-titles">Pick Up</p>
                        <a
                          class="mini-titles-val"
                          target="_blank"
                          :href="getLocationUrl(shipment.pickup_location)"
                        >
                          {{
                            shipment?.pickup_location?.city +
                            ", " +
                            shipment?.pickup_location?.state
                          }}
                        </a>
                      </div>

                      <div class="d-flex align-center connection-arrow">
                        <v-img src="../assets/icons/forward_arrow.png"></v-img>
                      </div>

                      <div class="d-flex flex-column sub-section">
                        <p class="mini-titles">Drop Off</p>
                        <a
                          class="mini-titles-val"
                          target="_blank"
                          :href="getLocationUrl(shipment.dropoff_location)"
                        >
                          {{
                            shipment?.dropoff_location?.city +
                            ", " +
                            shipment?.dropoff_location?.state
                          }}
                        </a>
                      </div>
                    </div>

                    <div
                      class="item-list-info-pending d-flex info-mobile-view ml-lg-2 mb-4 mb-lg-0 align-center"
                    >
                      <div class="d-flex flex-column">
                        <p class="mini-titles">Type</p>
                        <div
                          class="mini-titles-val completed-shipment-type d-flex align-center"
                        >
                          <p class="mb-0">
                            {{ shipment.region_service?.value.name }}
                          </p>
                        </div>
                      </div>

                      <div class="truck-type-img">
                        <v-img
                          :src="shipment.region_service?.value.links.original"
                        ></v-img>
                      </div>

                      <div class="d-flex flex-column ml-8 ml-lg-0">
                        <p class="mini-titles">Price</p>
                        <div class="mini-titles-val">
                          {{
                            shipment?.quote_result?.carrier_cut
                              ? parseFloat(
                                  shipment?.quote_result?.carrier_cut
                                ).toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })
                              : null
                          }}
                        </div>
                      </div>
                    </div>

                    <div
                      class="item-list-info-pending info-mobile-view d-flex justify-space-between justify-lg-space-around ml-lg-3"
                    >
                      <div class="d-flex flex-column btn-dailog">
                        <v-btn
                          class="accept-btn info-btns white--text green"
                          dark
                          block
                          color="black"
                          @click="
                            acceptOrderDialog = true;
                            selectShipment(shipment.id, shipment, s);
                          "
                        >
                          Accept
                        </v-btn>
                      </div>

                      <div class="d-flex flex-column btn-dailog">
                        <div class="reject-btn">
                          <v-btn
                            @click="
                              rejectOrderDialog = true;
                              selectShipment(shipment.id, shipment, s);
                            "
                            class="reject-btn info-btns red--text"
                            outlined
                          >
                            Reject
                          </v-btn>
                        </div>
                      </div>
                    </div>

                    <div
                      class="item-list-info-pending info-mobile-view d-flex flex-column align-end align-lg-center mt-5 mt-lg-0"
                    >
                      <router-link
                        :to="{
                          path: `/shipment-details/homepage/10/${shipment.id}`,
                        }"
                      >
                        See Details
                      </router-link>
                    </div>

                    <!-- <div class="item-list-info-pending d-none d-lg-flex align-center justify-end">
                    <v-icon color="#191919" size="24">mdi-dots-vertical</v-icon>
                </div> -->
                  </div>
                </v-row>
              </template>
            </div>

            <div
              v-if="isLoading"
              style="background-color: #fff"
              :class="{ 'tablet-mobile-grid-view': windowWidth < 1264 }"
            >
              <template v-for="n in 3">
                <v-row
                  :key="n"
                  :class="{ 'pr-4': windowWidth < 1264 }"
                  class="section-card mt-6 no-gutters"
                >
                  <div
                    class="single-card d-flex align-center flex-column flex-lg-row info-mobile-view"
                  >
                    <div
                      class="item-list-info-pending info-mobile-view d-flex flex-column align-self-center"
                    >
                      <p class="mini-titles">Status</p>
                      <div class="shipment-status-pending">
                        <v-skeleton-loader
                          class="skeleton-remove-padding skeleton-full-width"
                          max-width="90"
                          type="list-item"
                        ></v-skeleton-loader>
                      </div>
                    </div>

                    <div
                      class="item-list-info-pending d-flex flex-row-reverse flex-lg-row info-mobile-view justify-end justify-lg-space-around"
                    >
                      <div
                        class="d-flex flex-column ml-5 ml-lg-0 skeleton-mini-width"
                      >
                        <p class="mini-titles">Created Date</p>
                        <v-skeleton-loader
                          class="skeleton-remove-padding skeleton-full-width"
                          max-width="90"
                          type="list-item"
                        ></v-skeleton-loader>
                      </div>
                      <div
                        class="d-flex flex-column ml-5 ml-lg-0 skeleton-mini-width"
                      >
                        <p class="mini-titles">Pickup Date</p>
                        <v-skeleton-loader
                          class="skeleton-remove-padding skeleton-full-width"
                          max-width="90"
                          type="list-item"
                        ></v-skeleton-loader>
                      </div>

                      <div class="d-flex flex-column skeleton-mini-width">
                        <p class="mini-titles">Pickup Time</p>
                        <v-skeleton-loader
                          class="skeleton-remove-padding skeleton-full-width"
                          max-width="90"
                          type="list-item"
                        ></v-skeleton-loader>
                      </div>
                    </div>

                    <div
                      class="item-list-info-pending d-flex info-mobile-view ml-lg-10"
                    >
                      <div class="d-flex flex-column skeleton-smallest-width">
                        <p class="mini-titles">Pick Up</p>
                        <v-skeleton-loader
                          class="skeleton-remove-padding skeleton-full-width"
                          max-width="90"
                          type="list-item"
                        ></v-skeleton-loader>
                      </div>

                      <div class="d-flex align-center connection-arrow">
                        <v-img src="../assets/icons/forward_arrow.png"></v-img>
                      </div>

                      <div class="d-flex flex-column skeleton-smallest-width">
                        <p class="mini-titles">Drop Off</p>
                        <v-skeleton-loader
                          class="skeleton-remove-padding skeleton-full-width"
                          max-width="90"
                          type="list-item"
                        ></v-skeleton-loader>
                      </div>
                    </div>

                    <div
                      class="skeleton-mini-width item-list-info-pending d-flex info-mobile-view mb-4 mb-lg-0 justify-space-between align-center"
                    >
                      <div class="d-flex flex-column skeleton-smallest-width">
                        <p class="mini-titles">Type</p>
                        <v-skeleton-loader
                          class="skeleton-remove-padding skeleton-full-width"
                          max-width="90"
                          type="list-item"
                        ></v-skeleton-loader>
                      </div>

                      <div class="truck-type-img">
                        <v-skeleton-loader
                          class="skeleton-remove-padding skeleton-full-width"
                          max-height="40"
                          max-width="90"
                          type="image"
                        ></v-skeleton-loader>
                      </div>

                      <div
                        class="d-flex flex-column ml-8 ml-lg-3 skeleton-smallest-width"
                      >
                        <p class="mini-titles">Price</p>
                        <v-skeleton-loader
                          max-width="90"
                          class="skeleton-remove-padding skeleton-full-width"
                          type="list-item"
                        ></v-skeleton-loader>
                      </div>
                    </div>

                    <div
                      class="item-list-info-pending info-mobile-view d-flex justify-space-between justify-lg-space-around ml-lg-3"
                    >
                      <div class="d-flex flex-column btn-dailog">
                        <v-skeleton-loader
                          class="skeleton-full-width"
                          max-width="90"
                          type="button"
                        ></v-skeleton-loader>
                      </div>

                      <div class="d-flex flex-column btn-dailog">
                        <v-skeleton-loader
                          max-width="90"
                          class="skeleton-full-width"
                          type="button"
                        ></v-skeleton-loader>
                      </div>
                    </div>

                    <div
                      class="item-list-info-pending info-mobile-view d-flex flex-column align-end align-lg-center mt-5 mt-lg-0"
                    >
                      <v-skeleton-loader
                        max-width="90"
                        class="skeleton-full-width"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>
                  </div>
                </v-row>
              </template>
            </div>

            <template v-if="!requestStatus && pendingShipmentList.length === 0">
              <h4 class="empty-msg">No pending shipments</h4>
            </template>

            <br />
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div id="active-shipments" class="main-content pa-5">
          <div class="d-flex align-center justify-space-between">
            <p class="section-title mb-0 mb-sm-2">Active Shipments</p>

            <v-btn
              @click="navigateTo('/shipment-list', 0)"
              class="d-none d-sm-block white--text rounded-md"
              dark
              dense
              width="180"
              color="black"
            >
              View all {{ totals[0] !== undefined ? `(${totals[0]})` : "" }}
            </v-btn>
          </div>

          <div class="middle-content">
            <div
              style="background-color: #fff"
              :class="{
                'tablet-mobile-grid-view':
                  activeShipmentList &&
                  activeShipmentList.length !== 0 &&
                  windowWidth < 1264,
              }"
            >
              <template v-for="shipment of activeShipmentList">
                <v-row
                  :key="shipment.id"
                  :class="{ 'pr-4': windowWidth < 1264 }"
                  class="section-card mt-6 no-gutters"
                >
                  <div
                    class="single-card d-flex align-center flex-column flex-lg-row info-mobile-view"
                  >
                    <div
                      class="item-list-info-active info-mobile-view d-flex align-self-center justify-start justify-lg-space-between"
                    >
                      <div class="d-flex flex-column">
                        <p class="mini-titles">Status</p>
                        <div class="shipment-status-active">Active</div>
                      </div>
                    </div>
                    <div
                      class="item-list-info-active info-mobile-view d-flex align-self-center justify-start"
                    >
                      <div class="d-flex flex-column ml-lg-0">
                        <p class="mini-titles">Created Date</p>
                        <div
                          v-if="shipment.created_at != null"
                          class="mini-titles-val active-pickup-date"
                        >
                          {{
                            formatDate(shipment.created_at).format("MM/DD/YYYY")
                          }}
                        </div>
                        <div
                          v-if="!shipment.created_at"
                          class="mini-titles-val active-pickup-date"
                        >
                          As soon as possible
                        </div>
                      </div>

                      <div class="d-flex flex-column ml-8 ml-lg-0">
                        <p class="mini-titles">Pickup Date</p>
                        <div
                          v-if="shipment.pickup_time != null"
                          class="mini-titles-val active-pickup-date"
                        >
                          {{
                            formatDate(shipment.pickup_time).format(
                              "MM/DD/YYYY"
                            )
                          }}
                        </div>
                        <div
                          v-if="shipment.pickup_time == null"
                          class="mini-titles-val active-pickup-date"
                        >
                          As soon as possible
                        </div>
                      </div>
                    </div>

                    <div
                      class="item-list-info-active d-flex info-mobile-view ml-lg-12"
                    >
                      <div class="d-flex flex-column">
                        <p class="mini-titles">Pick Up</p>

                        <a
                          class="mini-titles-val"
                          target="_blank"
                          :href="getLocationUrl(shipment.pickup_location)"
                        >
                          {{
                            shipment?.pickup_location?.city +
                            ", " +
                            shipment?.pickup_location?.state
                          }}
                        </a>
                      </div>

                      <div class="d-flex align-center connection-arrow">
                        <v-img src="../assets/icons/forward_arrow.png"></v-img>
                      </div>

                      <div class="d-flex flex-column">
                        <p class="mini-titles">Drop Off</p>
                        <a
                          class="mini-titles-val"
                          target="_blank"
                          :href="getLocationUrl(shipment.dropoff_location)"
                        >
                          {{
                            shipment?.dropoff_location?.city +
                            ", " +
                            shipment?.dropoff_location?.state
                          }}
                        </a>
                      </div>
                    </div>

                    <div
                      class="item-list-info-active d-flex info-mobile-view align-center"
                    >
                      <div class="d-flex flex-column">
                        <p class="mini-titles mb-0">Type</p>
                        <div class="mini-titles-val completed-shipment-type">
                          <p class="mb-0">
                            {{ shipment.region_service?.value.name }}
                          </p>
                        </div>
                      </div>

                      <div class="truck-type-img" v-if="windowWidth > 1264">
                        <v-img
                          :src="shipment.region_service?.value.links.original"
                        ></v-img>
                      </div>

                      <div class="d-flex flex-column ml-8 ml-lg-0">
                        <p class="mini-titles">Driver</p>
                        <div class="mini-titles-val">
                          {{
                            shipment.driver
                              ? shipment.driver.name
                              : "Not assigned"
                          }}
                        </div>
                      </div>

                      <div class="d-flex flex-column ml-8 ml-lg-0">
                        <p class="mini-titles">Price</p>
                        <div class="mini-titles-val">
                          {{
                            shipment?.quote_result?.carrier_cut
                              ? parseFloat(
                                  shipment?.quote_result?.carrier_cut
                                ).toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })
                              : null
                          }}
                        </div>
                      </div>
                    </div>

                    <div
                      class="item-list-info-active info-mobile-view mt-4 mt-lg-0 d-flex justify-space-between justify-lg-space-around"
                    >
                      <div
                        class="d-flex flex-column justify-end justify-lg-center mb-1 mb-lg-0"
                      >
                        <router-link
                          :to="{
                            path: `/shipment-details/homepage/0/${shipment.id}`,
                          }"
                        >
                          See Details
                        </router-link>
                      </div>

                      <div class="truck-type-img" v-if="windowWidth <= 1264">
                        <v-img
                          :src="shipment.region_service?.value.links.original"
                        ></v-img>
                      </div>
                    </div>
                  </div>
                </v-row>
              </template>
            </div>

            <div
              v-if="isLoading"
              style="background-color: #fff"
              :class="{ 'tablet-mobile-grid-view': windowWidth < 1264 }"
            >
              <template v-for="n in 3">
                <v-row
                  :key="n"
                  :class="{ 'pr-4': windowWidth < 1264 }"
                  class="section-card mt-6 no-gutters"
                >
                  <div
                    class="single-card d-flex align-center flex-column flex-lg-row info-mobile-view"
                  >
                    <div
                      class="item-list-info-active info-mobile-view d-flex align-self-center justify-start"
                    >
                      <div class="d-flex flex-column skeleton-smallest-width">
                        <p class="mini-titles">Status</p>
                        <div class="shipment-status-active">
                          <v-skeleton-loader
                            class="skeleton-remove-padding skeleton-full-width"
                            max-width="90"
                            type="list-item"
                          ></v-skeleton-loader>
                        </div>
                      </div>
                    </div>

                    <div
                      class="item-list-info-active d-flex info-mobile-view ml-lg-12"
                    >
                      <div
                        class="d-flex flex-column ml-lg-0 skeleton-smallest-width"
                      >
                        <p class="mini-titles">Created Date</p>
                        <v-skeleton-loader
                          class="skeleton-remove-padding skeleton-full-width"
                          max-width="90"
                          type="list-item"
                        ></v-skeleton-loader>
                      </div>
                      <div
                        class="d-flex flex-column ml-8 ml-lg-0 skeleton-smallest-width"
                      >
                        <p class="mini-titles">Pickup Date</p>
                        <v-skeleton-loader
                          class="skeleton-remove-padding skeleton-full-width"
                          max-width="90"
                          type="list-item"
                        ></v-skeleton-loader>
                      </div>
                    </div>

                    <div
                      class="item-list-info-active d-flex info-mobile-view ml-lg-12"
                    >
                      <div class="d-flex flex-column skeleton-smallest-width">
                        <p class="mini-titles">Pick Up</p>
                        <v-skeleton-loader
                          class="skeleton-remove-padding skeleton-full-width"
                          max-width="90"
                          type="list-item"
                        ></v-skeleton-loader>
                      </div>

                      <div class="d-flex align-center connection-arrow">
                        <v-img src="../assets/icons/forward_arrow.png"></v-img>
                      </div>

                      <div class="d-flex flex-column skeleton-smallest-width">
                        <p class="mini-titles">Drop Off</p>
                        <v-skeleton-loader
                          class="skeleton-remove-padding skeleton-full-width"
                          max-width="90"
                          type="list-item"
                        ></v-skeleton-loader>
                      </div>
                    </div>

                    <div
                      class="item-list-info-active d-flex info-mobile-view align-center justify-space-between"
                    >
                      <div class="d-flex flex-column skeleton-smallest-width">
                        <p class="mini-titles mb-0">Type</p>
                        <v-skeleton-loader
                          class="skeleton-remove-padding skeleton-full-width"
                          max-width="90"
                          type="list-item"
                        ></v-skeleton-loader>
                      </div>

                      <div class="truck-type-img" v-if="windowWidth > 1264">
                        <v-skeleton-loader
                          class="skeleton-remove-padding skeleton-full-width"
                          max-height="40"
                          max-width="90"
                          type="image"
                        ></v-skeleton-loader>
                      </div>

                      <div
                        class="d-flex flex-column ml-8 ml-lg-3 skeleton-smallest-width"
                      >
                        <p class="mini-titles">Driver</p>
                        <v-skeleton-loader
                          class="skeleton-remove-padding skeleton-full-width"
                          max-width="90"
                          type="list-item"
                        ></v-skeleton-loader>
                      </div>

                      <div
                        class="d-flex flex-column ml-8 ml-lg-0 skeleton-smallest-width"
                      >
                        <p class="mini-titles">Price</p>
                        <v-skeleton-loader
                          class="skeleton-remove-padding skeleton-full-width"
                          max-width="90"
                          type="list-item"
                        ></v-skeleton-loader>
                      </div>
                    </div>

                    <div
                      class="item-list-info-active info-mobile-view mt-4 mt-lg-0 d-flex justify-space-between justify-lg-space-around"
                    >
                      <v-skeleton-loader
                        class="skeleton-remove-padding skeleton-full-width"
                        max-width="90"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>
                  </div>
                </v-row>
              </template>
            </div>

            <template v-if="!requestStatus && activeShipmentList.length === 0">
              <h4 class="empty-msg">No active shipments</h4>
            </template>

            <br />
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="featured-footer" v-if="windowWidth >= 600">
          <VueSlickCarousel v-bind="settings">
            <div>
              <v-img
                class="shipment-pic"
                src="../assets/images/banner1.svg"
              ></v-img>
            </div>

            <div>
              <v-img
                class="shipment-pic"
                src="../assets/images/banner2.svg"
              ></v-img>
            </div>

            <div>
              <v-img
                class="shipment-pic"
                src="../assets/images/banner3.svg"
              ></v-img>
            </div>

            <div>
              <v-img class="shipment-pic" src="../assets/images/banner4.svg">
                <div class="feature-contact">
                  <div class="right-footer-section">
                    <p class="feature-contact-title mb-3 mb-sm-1 mb-md-2">
                      Contact Us
                    </p>

                    <div class="d-flex flex-column flex-md-row">
                      <div class="d-flex flex-row flex-md-column justify-start">
                        <div class="icon-footer-section d-flex align-center">
                          <img
                            src="https://b19-emails.s3.us-east-2.amazonaws.com/phone.png"
                          />
                          <span class="footer-icons-txt ml-1">Phone</span>
                        </div>

                        <p class="email-link mb-0 ml-4 ml-sm-2 ml-md-0">
                          <a href="mailto:dispatch@breaker19.app"
                            >832-603-4500</a
                          >
                        </p>
                      </div>

                      <div
                        class="d-flex flex-row flex-md-column justify-start ml-xs-0 ml-sm-0 ml-md-3"
                      >
                        <div class="icon-footer-section d-flex align-center">
                          <img
                            src="https://b19-emails.s3.us-east-2.amazonaws.com/email.png"
                          />
                          <span class="footer-icons-txt ml-1">Email</span>
                        </div>

                        <p class="email-link mb-0 ml-4 ml-sm-2 ml-md-0">
                          <a href="mailto:dispatch@breaker19.app"
                            >dispatch@breaker19.app</a
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </v-img>
            </div>
          </VueSlickCarousel>
        </div>

        <div
          class="featured-footer d-flex flex-column"
          v-if="windowWidth < 600"
        >
          <div class="shipment-pic">
            <v-img src="../assets/images/banner1.svg"></v-img>
          </div>

          <div>
            <v-img
              class="shipment-pic"
              src="../assets/images/banner2.svg"
            ></v-img>
          </div>

          <div>
            <v-img
              class="shipment-pic"
              src="../assets/images/banner3.svg"
            ></v-img>
          </div>

          <div>
            <v-img class="shipment-pic" src="../assets/images/banner4.svg">
              <div class="feature-contact">
                <div class="right-footer-section">
                  <p class="feature-contact-title mb-3 mb-sm-1 mb-md-2">
                    Contact Us
                  </p>

                  <div class="d-flex flex-column flex-md-row">
                    <div class="d-flex flex-row flex-md-column justify-start">
                      <div class="icon-footer-section d-flex align-center">
                        <img
                          src="https://b19-emails.s3.us-east-2.amazonaws.com/phone.png"
                        />
                        <span class="footer-icons-txt ml-1">Phone</span>
                      </div>

                      <p class="email-link mb-0 ml-4 ml-sm-2 ml-md-0">
                        <a href="mailto:dispatch@breaker19.app">832-603-4500</a>
                      </p>
                    </div>

                    <div
                      class="d-flex flex-row flex-md-column justify-start ml-xs-0 ml-sm-0 ml-md-3"
                    >
                      <div class="icon-footer-section d-flex align-center">
                        <img
                          src="https://b19-emails.s3.us-east-2.amazonaws.com/email.png"
                        />
                        <span class="footer-icons-txt ml-1">Email</span>
                      </div>

                      <p class="email-link mb-0 ml-4 ml-sm-2 ml-md-0">
                        <a href="mailto:dispatch@breaker19.app"
                          >dispatch@breaker19.app</a
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </v-img>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="rejectOrderDialog" persistent max-width="440">
      <v-card class="pa-6 accept-order-vcard">
        <v-card-title
          class="reject-order-main-title pl-0 pb-1 pt-0 pr-0 d-flex justify-space-between"
        >
          Are you sure you want to reject this order?
        </v-card-title>
        <div
          class="title-info d-flex align-center align-sm-start align-start mb-3 mt-3 pl-0"
        >
          <img width="17px" src="../assets/icons/info-icon.png" />
          <span class="pl-1 accept-order-info">
            You won’t be able to revisit this order again</span
          >
        </div>

        <v-card-actions class="pa-0">
          <v-spacer></v-spacer>
          <v-btn
            class="accept-order-btns rounded-md"
            width="50%"
            dense
            outlined
            text
            @click="rejectOrderDialog = false"
          >
            Back to list
          </v-btn>

          <v-btn
            class="accept-order-btns white--text rounded-md"
            dense
            width="50%"
            dark
            color="black"
            @click="
              rejectOrderDialog = false;
              orderAccept(false);
            "
          >
            Reject
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="acceptOrderDialog" persistent max-width="440">
      <v-card class="pa-6 accept-order-vcard">
        <v-card-title
          class="accept-order-main-title pl-0 pb-1 pt-0 pr-0 d-flex justify-space-between"
        >
          Accept This Order?
        </v-card-title>
        <div class="title-info d-flex align-start mb-2 pl-0">
          <img width="17px" src="../assets/icons/info-icon.png" />
          <span class="pl-1 font-weight-bold accept-order-info">
            You must assign a driver before you can accept an order. You must
            have added your driver in the
            <a href="#" class="color-black">drivers tab</a> for them to appear
            as an option. Please note that by accepting this shipment, you are
            agreeing to the rate shown.
          </span>
        </div>

        <div>
          <span class="accept-order">Choose driver</span>
          <v-select
            :items="driversList"
            v-model="selectedDriver"
            item-value="id"
            item-text="name"
            color="grey"
            class="accept-order-vselect"
            background-color="#F4F4F4"
            label="Select a driver"
            append-icon="mdi-chevron-down-box"
            height="10px"
            return-object
            solo
            dense
            flat
          >
          </v-select>
        </div>

        <div class="active-order-price mb-2">
          <p class="mb-0">Price (what you will make)</p>
          <p class="mb-0">
            {{
              selectedShipment?.quote_result?.carrier_cut
                ? selectedShipment?.quote_result?.carrier_cut.toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "USD",
                    }
                  )
                : ""
            }}
          </p>
        </div>

        <v-card-actions class="pa-0">
          <v-spacer></v-spacer>
          <v-btn
            class="accept-order-btns rounded-md"
            width="50%"
            dense
            outlined
            text
            @click="acceptOrderDialog = false"
          >
            Back to list
          </v-btn>

          <v-btn
            class="accept-order-btns white--text rounded-md"
            dense
            width="50%"
            dark
            color="black"
            @click="orderAccept(true)"
          >
            Accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <AppLoading :active.sync="isLoading" /> -->
  </div>
</template>

<style scoped lang="scss">
.homepage-styles {
  @import "../assets/styles/Homepage.scss";

  font-family: "Inter", sans-serif;
}
</style>

<script>
import { convertTimezone } from "@/helpers/timeHelper";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

import MobileHeader from "../components/mobile-header.vue";
import mixin from "../mixins/auth.mixin.js";
import moment from "moment/moment";

export default {
  name: "HomePage",
  components: {
    MobileHeader,
    VueSlickCarousel,
  },
  computed: {
    localStorage() {
      return localStorage;
    },
  },
  data: () => ({
    authMixins: mixin.methods,
    isLoading: false,
    requestStatus: false,
    settings: {
      dots: false,
      focusOnSelect: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      touchThreshold: 5,
    },

    acceptOrderDialog: false,
    rejectOrderDialog: false,
    pendingShipmentList: [],
    activeShipmentList: [],
    totals: [],
    driversList: [],
    windowWidth: 0,
    selectedShipmentId: null,
    selectedShipmentIndex: -1,
    selectedShipment: null,
    selectedDriver: null,
  }),

  created() {
    this.updateWindowWidth();
  },

  mounted() {
    this.getTotals();
    document.title = "Carrier Portal - Breaker19.app";

    window.addEventListener("resize", this.updateWindowWidth);

    this.loadShipments();
    this.loadUserData();
    this.loadDrivers();
  },

  filters: {
    split_using_space: function (value, returnType) {
      if (!value) return "";
      value = value.split(" ");
      if (returnType === "date") {
        let splitted_date = value[0].split("-");
        let reformat =
          splitted_date[1] + "/" + splitted_date[2] + "/" + splitted_date[0];
        return reformat;
      } else if (returnType === "time") return value[1];
      else return value[0] + " " + value[1];
    },

    toTimezone(time) {
      return convertTimezone(time, localStorage.timezone);
    },
  },

  methods: {
    formatDate(date) {
      let tz = "Etc/GMT";

      if (localStorage.timezone) {
        tz = localStorage.timezone;
      }

      return moment(date).tz(tz);
    },
    getTotals() {
      let config = {
        headers: {
          Authorization: localStorage.authToken,
        },
      };

      this.$api
        .get("carriers/shipments?status=active&page=1", config)
        .then(({ data }) => {
          this.totals[0] = data.total;
        });

      this.$api
        .get("carriers/shipments?status=pending&page=1", config)
        .then(({ data }) => {
          this.totals[1] = data.total;
        });
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },

    navigateTo(route, tabNo) {
      this.$router.push(route);
      localStorage.shipmentTabNo = tabNo;
    },

    selectShipment(shipmentId, shipmentData, shipmendIndex) {
      this.selectedShipmentId = shipmentId;
      this.selectedShipment = shipmentData;
      this.selectedShipmentIndex = shipmendIndex;
      this.selectedDriver = shipmentData.driver;
    },

    loadUserData() {
      let config = {
        headers: {
          Authorization: this.localStorage.authToken,
        },
      };

      this.$api
        .get("auth/carrier/infos", config)
        .then((response) => {
          if (response.status === 200) {
            localStorage.timezone = response.data.carrier.time_zone;
            localStorage.profileImg = response.data.profile_url;
            localStorage.username = response.data.carrier.name;

            this.$forceUpdate();
          }
        })
        .catch(async (err) => {
          console.log(err);
        });
    },

    async loadShipments() {
      this.isLoading = true;
      this.requestStatus = true;

      const config = { headers: { Authorization: localStorage.authToken } };

      try {
        const pendingResponse = await this.$api.get(`carriers/shipments?status=pending`, config);

        if (pendingResponse.status === 200) {
          this.pendingShipmentList = pendingResponse.data?.data || [];
        }
      } catch (err) {
        if (err.response && err.response?.status === 401) {
          this.$toast.error("Unauthorized to load pending shipments", { duration: 5000 });
          this.authMixins.clearAuthData();

          await this.$router.push("/signin");

          return
        } else {
          this.$toast.error("Failed to load pending shipments", { duration: 5000 });
        }
      } finally {
        this.isLoading = false;
        this.requestStatus = false;
      }

      try {
        const activeResponse = await this.$api.get(`carriers/shipments?status=active`, config);

        if (activeResponse.status === 200) {
          this.activeShipmentList = activeResponse.data?.data || [];
        }
      } catch (err) {
        if (err.response && err.response?.status === 401) {
          this.$toast.error("Unauthorized to load active shipments", { duration: 5000 });
          this.authMixins.clearAuthData();

          await this.$router.push("/signin");
        } else {
          this.$toast.error("Failed to load active shipments", { duration: 5000 });
        }
      }
    },

    loadDrivers() {
      let config = {
        headers: {
          Authorization: localStorage.authToken,
        },
      };

      this.$api
        .get(`drivers/list`, config)
        .then((response) => {
          if (response.status === 200) {
            let tempDrivers = response.data.data;
            this.driversList = tempDrivers;
            this.errorStatus = "";
          }
        })
        .catch((err) => {
          console.log(err);
          this.errorStatus = "No drivers exist";
        });
    },

    async orderAccept(status) {
      if (status && !this.selectedDriver) {
        this.$toast.error("Please select a driver", { duration: 5000 });

        return;
      }

      if (this.requestStatus) return

      this.requestStatus = true;
      this.acceptOrderDialog = false;

      const config = { headers: { Authorization: localStorage.authToken } };

      const driver = {
        driver_id: this.selectedDriver ? this.selectedDriver.id : null
      };

      const orderUrl = status
        ? `carriers/shipments/${this.selectedShipmentId}/accept`
        : `carriers/shipments/${this.selectedShipmentId}/decline`;

      try {
        const response = await this.$api.patch(orderUrl, driver, config);

        if (response.status === 200) {
          if (status) {
            this.$toast.info("Order accepted successfully", { duration: 5000 });
          } else {
            this.$toast.info("Order has been rejected", { duration: 5000 });
          }

          this.pendingShipmentList.splice(this.selectedShipmentIndex, 1);

          await this.loadShipments();
        }
      } catch (err) {
        if (
          err.response &&
          err.response?.data?.code === 110001 &&
          err.response?.data?.message === "Carrier shipment mismatch"
        ) {
          this.$toast.error("Order has been accepted by a different carrier", { duration: 5000 });

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else if (err.response && err.response?.status === 401) {
          this.authMixins.clearAuthData();
          this.$toast.error("Unauthorized to update order", { duration: 5000 });

          await this.$router.push("/signin");
        } else {
          this.$toast.error("Order update failed", { duration: 5000 });
        }
      } finally {
        this.requestStatus = false;
      }
    },

    getLocationUrl(location) {
      if (!location) return "";
      if (location?.source === 2) {
        const address = [
          location.line1,
          location.line2,
          location.city,
          location.state,
          location.zip,
        ]
          .filter((segment) => segment && !segment.startsWith("_void_"))
          .join(", ");

        return `https://maps.google.com/maps?z=11&t=m&q=loc:${encodeURIComponent(
          address
        )}`;
      }

      return `https://maps.google.com/maps?z=11&t=m&q=loc:${location.latitude}+${location.longitude}`;
    },
  },
  beforeDestroy() {},
};
</script>
