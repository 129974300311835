<template>
  <div class="skeleton-loader content-section" v-if="isLoading">
    <template v-for="n in 1">
      <div class="content d-flex flex-column flex-md-row" :key="n">
        <div
          class="left-content-section d-flex flex-row flex-sm-column justify-space-between justify-sm-start"
        >
          <div class="d-flex flex-column">
            <p>Truck Type</p>

            <div class="location-map-icon d-flex align-center mb-1">
              <v-icon class="text-center icon-color">mdi-truck</v-icon>

              <p class="region-name skeleton-full-width">
                <v-skeleton-loader
                  class="skeleton-mini-width skeleton-remove-padding"
                  max-width="100"
                  type="list-item"
                />
              </p>
            </div>
          </div>

          <div class="region-detail d-flex flex-column">
            <div class="d-flex flex-column">
              <v-skeleton-loader
                class="skeleton-mini-width skeleton-remove-padding"
                max-width="100"
                type="list-item"
              />
            </div>
          </div>
        </div>

        <div class="d-flex flex-column" style="width: 100%">
          <div class="right-content-section">
            <div class="rate-header">
              <div class="rate">
                <div class="tableContainer">
                  <div>
                    <v-simple-table class="first-table" dense item-key="name">
                      <template v-slot:top>
                        <thead class="table-first-col">
                          <tr>
                            <th class="pl-2 text-left rate-title rounded-l-lg">
                              <v-skeleton-loader
                                class="skeleton-mini-width skeleton-remove-padding"
                                max-width="180"
                                type="list-item"
                              />
                            </th>
                          </tr>
                        </thead>

                        <tbody class="options">
                          <tr v-for="(_, m) in 7" :key="m" style="height: 39px">
                            <td class="mileage-title pl-3 skeleton-full-width">
                              <v-skeleton-loader
                                class="skeleton-full-width skeleton-remove-padding"
                                max-width="180"
                                type="list-item"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>

                  <div class="second-table">
                    <v-simple-table dense item-key="name" class="d-flex">
                      <template v-slot:top>
                        <template v-for="r of 5">
                          <div :key="r">
                            <thead class="table-content-col">
                              <tr>
                                <th class="text-left">
                                  <v-skeleton-loader
                                    class="skeleton-mini-width skeleton-remove-padding"
                                    max-width="180"
                                    type="list-item"
                                  />
                                </th>
                              </tr>
                            </thead>

                            <tbody class="options">
                              <tr v-for="p in 7" :key="p" style="height: 39px">
                                <td>
                                  <div
                                    class="ma-2 option-input d-flex align-center"
                                  >
                                    <div
                                      class="d-flex skeleton-full-width"
                                      style="width: inherit"
                                    >
                                      <v-skeleton-loader
                                        class="skeleton-full-width skeleton-remove-padding"
                                        max-width="480"
                                        type="list-item"
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </div>
                        </template>
                      </template>
                    </v-simple-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.configure-region-rates {
  @import "@/assets/styles/ConfigureRegionRates.scss";

  font-family: "Outfit", sans-serif;
  background-color: #f4f4f4;
  padding: 25px;
}
</style>

<script>
export default {
  name: "ViewSkeleton",
  props: {
    isLoading: { type: Boolean, required: true },
  },
};
</script>
